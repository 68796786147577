import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faPhone,
  faStar,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

const Pricing = () => {
  return (
    <section className='bg-dark py-3 py-md-5' id='pricing'>
      <div className='container'>
        <div className='s-60'></div>
        <h2 className='title-section c-white tc'>Pricing</h2>
        <div className='s-10'></div>
        <p className='c-white tc title'>
          Select the plan that best matches your certification goals
          <br />
          <em className='fw-400 c-blue'>
            <sup>*</sup> All payments are non-refundable
          </em>
        </p>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-lg-4 my-2'>
            <div className='card p-4'>
              <div className='card-body'>
                <div>
                  <h3 className='c-blue tc'>
                    Essential
                    <br />
                    <small className='c-blue'>License</small>
                  </h3>
                  <p className='title-intro-sub c-blue tc'>
                    <strong>$50</strong>
                  </p>
                  <ul className='list-unstyled py-3 px-2'>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faCheckCircle} className='me-3' />
                      <span>Access to entry-level assignments</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faCheckCircle} className='me-3' />
                      <span>Basic training materials</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faClock} className='me-3' />
                      <span>Certification within 48 hours</span>
                    </li>
                  </ul>
                </div>
                <div className='mt-3 tc'>
                  <a href='#cta' className='button'>
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4 my-2'>
            <div className='card p-4'>
              <div className='card-body'>
                <div>
                  <h3 className='c-blue tc'>
                    Advanced
                    <br />
                    <small className='c-blue'>License</small>
                  </h3>
                  <p className='title-intro-sub c-blue tc'>
                    <strong>$100</strong>
                  </p>
                  <ul className='list-unstyled py-3 px-2'>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faCheckCircle} className='me-3' />
                      <span>Priority access to higher-paying assignments</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faCheckCircle} className='me-3' />
                      <span>Advanced training resources</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faClock} className='me-3' />
                      <span>Certification within 24 hours</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faPhone} className='me-3' />
                      <span>Dedicated support line</span>
                    </li>
                  </ul>
                </div>
                <div className='mt-3 tc'>
                  <a href='#cta' className='button'>
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4 my-2'>
            <div className='card p-4'>
              <div className='card-body'>
                <div>
                  <h3 className='c-blue tc'>
                    Professional
                    <br />
                    <small className='c-blue'>License</small>
                  </h3>
                  <p className='title-intro-sub c-blue tc'>
                    <strong>$150</strong>
                  </p>
                  <ul className='list-unstyled py-3 px-2'>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faCheckCircle} className='me-3' />
                      <span>All-inclusive access to top-tier assignments</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faCheckCircle} className='me-3' />
                      <span>Comprehensive training suite</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faClock} className='me-3' />
                      <span>Immediate certification</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faPhone} className='me-3' />
                      <span>24/7 dedicated support</span>
                    </li>
                    <li className='mb-2'>
                      <FontAwesomeIcon icon={faStar} className='me-3' />
                      <span>Exclusive career opportunities</span>
                    </li>
                  </ul>
                </div>
                <div className='mt-3 tc'>
                  <a href='#cta' className='button'>
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='s-60'></div>
      </div>
    </section>
  );
};

export { Pricing };
