import React from 'react';
import i1 from '../../assets/icons/i1.svg';
import i2 from '../../assets/icons/i2.svg';
import i3 from '../../assets/icons/i3.svg';

const Conditions = () => {
  return (
    <section className='bg-blue py-5' id='conditions'>
      <div className='container'>
        <div className='s-60'></div>
        <h2 className='title-section c-dark tc'>Conditions</h2>
        <div className='s-10'></div>
        <p className='text-center'>
          To qualify for a Remote Agent License, you must meet the following
          criteria:
        </p>
        <div className='s-60'></div>
        <div className='row text-center'>
          <div className='col-md-4 px-lg-5'>
            <img src={i1} alt='Identity Verification' />
            <h4 className='title py-3'>Identity Verification</h4>
            <p>Confirm your identity with valid government-issued documentation.</p>
          </div>
          <div className='col-md-4 px-lg-5'>
            <img src={i2} alt='Criminal Record Check' />
            <h4 className='title py-3'>Criminal Record Check</h4>
            <p>Ensure you have no prior criminal convictions or legal restrictions.</p>
          </div>
          <div className='col-md-4 px-lg-5'>
            <img src={i3} alt='Eligible Location' />
            <h4 className='title py-3'>Eligible Location</h4>
            <p>Verify you reside in a location where assignments are accessible.</p>
          </div>
          <div className='s-60'></div>
        </div>
      </div>
    </section>
  );
};

export { Conditions };
