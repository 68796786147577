import React from 'react';

const Footer = () => {
  return (
    <footer class='bg-dark text-white text-center py-5'>
      <div className='s-16'></div>
      <p className='px-4 px-sm-0'>
        &copy; 2024 Stealth Careers Hub. All Rights Reserved.
      </p>
      <a href='#hero' class='c-blue'>
        Back to Top
      </a>
    </footer>
  );
};

export { Footer };
