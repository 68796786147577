import React from 'react';
import { Navigation } from '../components/parts/Navigation';
import { Hero } from '../components/parts/Hero';
import { License } from '../components/parts/License';
import { Conditions } from '../components/parts/Conditions';
import { Requirements } from '../components/parts/Requirements';
import { Pricing } from '../components/parts/Pricing';
import { CallToAction } from '../components/parts/CallToAction';
import { Footer } from '../components/parts/Footer';

const Home = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <License />
      <Conditions />
      <Requirements />
      <Pricing />
      <CallToAction />
      <Footer />
    </>
  );
};

export { Home };
