import React from 'react';
import why from '../../assets/why.webp';
import how from '../../assets/how.webp';

const License = () => {
  return (
    <section className='container py-2 my-2 py-md-5 my-md-5' id='license'>
      <div className='row'>
        <div className='col-lg-6 p-5 flex jcc aic'>
          <div>
            <h2>🌟 Why You Need a License</h2>
            <p className='l-8 pb-0'>
              A Remote Agent License boosts your credibility, granting access to
              exclusive roles with competitive pay:
            </p>
            <ul className='c-list l-8'>
              <li>
                🖥️ <strong>Social Media Compliance Reviewer</strong>: Earn up to
                $20/hour
              </li>
              <li>
                📄 <strong>Digital Content Quality Specialist</strong>: Earn up
                to $25/hour
              </li>
              <li>
                🛒 <strong>Online Mystery Shopper</strong>: Earn up to $18/hour
              </li>
              <li>
                ☎️ <strong>Customer Support Evaluator</strong>: Earn up to
                $22/hour
              </li>
              <li>
                🔒 <strong>Cybersecurity Test Agent</strong>: Earn up to
                $30/hour
              </li>
            </ul>
          </div>
        </div>
        <div className='col-lg-6'>
          <img
            src={why}
            alt='Why You Need a License'
            width={'100%'}
            height={'auto'}
            className='obfc w-100 r-8'
          />
        </div>
        <div className='s-40'></div>
        <div className='col-lg-6'>
          <img
            src={how}
            alt='How It Works'
            width={'100%'}
            height={'auto'}
            className='obfc w-100 r-8'
          />
        </div>
        <div className='col-lg-6 p-5 flex jcc aic'>
          <div>
            <h2>⚙️ How It Works</h2>
            <ul className='c-list l-8'>
              <li>📝 &nbsp;Fill out a simple KYC form.</li>
              <li>💳 &nbsp;Pay for document verification.</li>
              <li>🎫 &nbsp;Receive your official QR-code license digitally.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export { License };
