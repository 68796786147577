import React from 'react';

const CallToAction = () => {
  return (
    <section id='cta' className='bg-dark py-5'>
      <div className='container text-center'>
        <h2 className='title-intro c-white'>Ready to Get License?</h2>
        <p className='lead c-white w-50 ma mx-auto'>
          Take the first step toward your career as a licensed Remote Agent
          today
        </p>
        <div className='s-20'></div>
        <style>
          {`
            .pp-QSYKP5WTTQUSC {
              text-align: center;
              border: none;
              border-radius: 1.5rem;
              min-width: 11.625rem;
              padding: 0 2rem;
              height: 2.625rem;
              font-weight: bold;
              background-color: #FFD140;
              color: #000000;
              font-family: "Helvetica Neue", Arial, sans-serif;
              font-size: 1rem;
              line-height: 1.25rem;
              cursor: pointer;
            }
            .paypal-img {
              height: 0.875rem;
              vertical-align: middle;
            }
            .cta-form {
              display: inline-grid;
              justify-items: center;
              align-content: start;
              gap: 1rem;
              width: 280px;
              height: 40px;
            }
          `}
        </style>
        <form
          action='https://www.paypal.com/ncp/payment/QSYKP5WTTQUSC'
          method='post'
          target='_top'
          className='cta-form'
        >
          <input className='pp-QSYKP5WTTQUSC' type='submit' value='Buy Now' />
          <img
            src='https://www.paypalobjects.com/images/Debit_Credit_APM.svg'
            alt='cards'
          />
          <section>
            Powered by{' '}
            <img
              src='https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-wordmark-color.svg'
              alt='paypal'
              className='paypal-img'
            />
          </section>
        </form>
        <div className='s-20'></div>
      </div>
    </section>
  );
};

export { CallToAction };
