import React from 'react';
import { useState } from 'react';
import logo from '../../logo.svg';

const Navigation = () => {
  const [show, setShow] = useState(false);
  return (
    <nav class='navbar navbar-expand-lg navbar-light bg-light px-3 px-sm-5'>
      <a class='navbar-brand' href='/' aria-label='logo'>
        <img
          src={logo}
          alt='Mystery Customer'
          width={48}
          height={48}
          className='logo'
        />
        <span className='c-theme fw-700 title-mini'>Stealth Careers Hub</span>
      </a>
      <button
        class={show ? 'navbar-toggler opened' : 'navbar-toggler'}
        type='button'
        data-bs-toggle='collapse'
        data-bs-target='#navbarNav'
        onClick={() => setShow(!show)}
      >
        <span class='one'></span>
        <span class='two'></span>
        <span class='three'></span>
      </button>
      <div
        class={show ? 'collapsed navbar-collapse' : 'collapse navbar-collapse'}
        id='navbarNav'
      >
        <ul class='navbar-nav ms-auto'>
          <li class='nav-item'>
            <a class='nav-link' href='#license' onClick={() => setShow(!show)}>
              License
            </a>
          </li>
          <li class='nav-item'>
            <a
              class='nav-link c-dark fw-500'
              href='#conditions'
              onClick={() => setShow(!show)}
            >
              Conditions
            </a>
          </li>
          <li class='nav-item'>
            <a
              class='nav-link c-dark fw-500'
              href='#requirements'
              onClick={() => setShow(!show)}
            >
              Requirements
            </a>
          </li>
          <li class='nav-item last'>
            <a
              class='nav-link c-dark fw-500'
              href='#pricing'
              onClick={() => setShow(!show)}
            >
              Pricing
            </a>
          </li>
          <li class='nav-item has-btn'>
            <a
              class='button outline small'
              href='#cta'
              onClick={() => setShow(!show)}
            >
              Enroll
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export { Navigation };
