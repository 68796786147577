import React from 'react';

const Requirements = () => {
  return (
    <section className='py-3 py-md-5' id='requirements'>
      <div className='container'>
        <div className='s-60'></div>
        <h2 className='title-section c-dark tc'>Requirements</h2>
        <div className='s-40'></div>
        <div className='row'>
          <div className='col-md-6 px-3 my-2'>
            <div className='bc-blue border p-5 r-8 h-300'>
              <p>- Applicants must be at least 18 years of age.</p>
              <p>
                - A valid email address and smartphone are required for
                communication and assignments.
              </p>
              <p>
                - A foundational understanding of customer service principles is
                essential.
              </p>
            </div>
          </div>
          <div className='col-md-6 px-3 my-2'>
            <div className='bc-blue border p-5 r-8 h-300'>
              <p>
                - Candidates must demonstrate the ability to follow detailed
                assignment instructions.
              </p>
              <p>
                - Access to reliable transportation may be necessary for certain
                tasks.
              </p>
              <p>
                - No prior professional experience is required, as training will
                be provided.
              </p>
            </div>
          </div>
        </div>
        <div className='s-60'></div>
      </div>
    </section>
  );
};

export { Requirements };
