import React from 'react';

const Hero = () => {
  return (
    <section id='hero'>
      <div className='container text-center'>
        <h1 class='title-intro c-white w-70 ma'>
          Become a Certified Remote Agent
        </h1>
        <p class='lead c-white w-60 ma'>
          Get licensed in just a few steps and unlock career opportunities in stealth professions.
        </p>
        <div className='s-20'></div>
        <a href='#cta' class='button'>
          Get Your License Now
        </a>
        <div className='s-20'></div>
      </div>
    </section>
  );
};

export { Hero };
